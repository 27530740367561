@charset 'UTF-8';
@import "/Users/VuongHuy/Website/vhost/furnituredome/src/scss/utility/_burger.scss";
@import "/Users/VuongHuy/Website/vhost/furnituredome/src/scss/utility/_flex.scss";
@import "/Users/VuongHuy/Website/vhost/furnituredome/src/scss/utility/_media-queries.scss";
@import "/Users/VuongHuy/Website/vhost/furnituredome/src/scss/utility/_mixin.scss";

#header {
  background: transparent;
  .header-logo {
    display: none;
  }
  .header-info {
    background: transparent;
  }
  .header-hamberger {
    @include SP {
      background: transparent;
    }
  }
  &.fixHeader {
    background: #fff;
    @include SP {
      background: transparent;
    }
    .header-logo {
      display: block;
      @include SP {
        display: none;
      }
    }
    .header-hamberger {
      @include SP {
        background: #fff;
      }
    }
  }
}
#wrap {
  margin-top: 0 !important;
}
.box-shop-sns {
  .box-shop {
    display: block !important;
  }
}
.top-mainvisual {
  position: relative;
  height: 100vh;
  @include SP {
    .ios.iphoneX &,
    .ios.ios12 &,
    .ios.ios13 &,
    .ios.ios14 & {
      height: calc(100vh - 112px);
    }
    .ios.iphone678 &,
    .ios.ios11.iphone678 &
    .ios.ios12.iphone678 &,
    .ios.ios13.iphone678 &,
    .ios.ios14.iphone678 & {
      height: calc(100vh - 75px);
    }
  }
  .mv-img {
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .myvideo {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    pointer-events: none;
    z-index: -1;
    @include SP {
      width: 130%;
    }
  }
  .mv-title {
    @include SP {
      padding: 0 45px;
    }
  }
  .mv-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    .btn-badge {
      display: block;
      position: absolute;
      right: 35px;
      bottom: 28px;
      .is-ipad.ios & {
        bottom: 60px;
      }
      @include SP {
        bottom: 20px;
        right: 19px;
      }
      img {
        @include SP {
          max-width: 128px;
          width: 100%;
        }
      }
    }
  }
}

.top-news {
  background: $color_light_gray;
  padding: 31px 0;
  @include SP {
    padding: 20px 0;
  }

  .top-news-inner {
    max-width: 790px;
    margin: 0 auto;
  }
  .box-news {
    @include flexbox();
    @include flex-wrap(wrap);
    @include align-items(center);
    @include SP {
      margin: 0 -8px;
    }
  }
  .top-news-title {
    width: 80px;
    @include flex(0 0 auto);
    @include font(20,20,0,500);
    @include ffRoboto;
    @include SP {
      display: none;
    }
  }
  .top-news-ctn {
    width: calc(100% - 80px);
    @include SP {
      width: 100%;
    }
    .news-item {
      @include clearfix();
      line-height: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .date {
        display: inline;
        @include font(13,18);
        color: $color_orange;
        margin-right: 18px;
        @include ffRoboto;
        @include SP {
          @include font(12,16);
          margin-right: 5px;
        }
      }
      .link {
        display: inline;
        @include font(14,18,25);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include SP {
          @include font(12,16);
        }
        a {
          text-decoration: underline;
        }
      }
    }
  }
}

.top-introduce {
  padding: 45px 0 100px;
  @include SP {
    padding: 13px 0 55px;
  }
  .top-introduce-info {
    margin-bottom: 50px;
    @include SP {
      margin-bottom: 26px;
    }
    .introduce-inner {
      max-width: 812px;
      margin: 0 auto;
    }
    .badge-holiday {
      position: relative;
      @include flexbox();
      @include justify-content(space-between);
      &:before {
        background: url('../../../'+$url_img_wpcontent+'common/icon/ico_holiday_l.svg') 0 0/100% auto no-repeat;
      }
      &:after {
        background: url('../../../'+$url_img_wpcontent+'common/icon/ico_holiday.svg') 0 0/100% auto no-repeat;
      }
      &:before,
      &:after {
        content: '';
        width: 90px;
        height: 74px;
        display: inline-block;
        @include SP {
          width: 63px;
          height: 56px;
        }
      }
    }
    .introduce-title {
      text-align: center;
      @include font(24,39,50,700);
      margin-top: -7px;
      margin-bottom: 20px;
      @include SP {
        @include font(18,28,25);
        margin-top: 4px;
        margin-bottom: 12px;
      }
      @include ANDR {
        @include font(16);
      }
    }
    .introduce-txt {
      text-align: center;
      @include font(15,34,25);
      margin-bottom: 38px;
      @include SP {
        @include font(15,28,0);
        margin-bottom: 32px;
      }
      @include ANDR {
        @include font(12);
      }
    }
    .introduce-icon {
      text-align: center;
      margin-top: 40px;
      @include SP {
        margin-top: 36px;
      }
    }
  }
  .top-introduce-img {
    @include keyframes(move) {
      0% {
          background-position: 0 0
      }
      100% {
          background-position: -1500px 0
      }
    }
    .introduce-list-img {
      // @include flexbox();
      // @include flex-wrap(wrap);
      // overflow: hidden;
      // margin: 0 -3.5%;
      background-size: 1500px 167px;
      background-repeat: repeat;
      height: 167px;
      animation: move 40s linear infinite;
      -webkit-animation: move 40s linear infinite;
      -moz-animation: move 40s linear infinite;
      @include SP {
        // margin: 0;
        height: 134px;
      }
      // li {
      //   width: 16.666%;
      //   overflow: hidden;
      //   @include SP {
      //     width: 50%;
      //     display: none;
      //     &:nth-child(2),
      //     &:nth-child(3) {
      //       display: block;
      //     }
      //   }
      //   .img {
      //     background-size: cover;
      //     background-position: center center;
      //     background-repeat: no-repeat;
      //     @include aspect-ratio(250,166);
      //     overflow: hidden;
      //     @include SP {
      //       @include aspect-ratio(187,134);
      //     }
      //   }
      // }
    }
  }
}

.top-sale {
  padding-bottom: 108px;
  @include SP {
    padding-bottom: 36px;
  }
  .top-sale-list {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(center);
    overflow: hidden;
    margin: 0 -1px;
    padding: 22px 0 40px;
    @include SP {
      margin: 0 -24px 0 -23px;
      padding-bottom: 20px;
    }
    li {
      width: 25%;
      padding: 0 1px;
      @include SP {
        width: 50%;
        margin-bottom: 5px;
      }
      a {
        @include flexbox();
        @include flex-direction(column);
        height: 100%;
        .img {
          display: block;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          @include aspect-ratio(264,151);
          overflow: hidden;
          @include flex(0 0 auto);
        }
        .tit {
          background: $color_light_gray;
          padding: 18px 15px;
          @include font(15,22);
          height: 100%;
          @include ANDR {
            @include font(13,20);
          }
          span {
            @include clamp(2);
          }
        }
      }
    }
  }
  .cm-button {
    @include SP {
      
    }
  }
}

.top-interior {
  padding-bottom: 100px;
  @include SP {
    padding-bottom: 76px;
  }
  .interior-article {
    @include flexbox();
    @include flex-wrap(wrap);
    margin-top: 32px;
    margin-bottom: 37px;
    @include SP {
      margin: 30px -23px 25px -22px;
    }
    .meta {
      // @include flexbox();
      // @include align-items(baseline);
      line-height: 1.2em;
      margin-top: 12px;
      .date {
        color: #fff;
        @include font(12,12,0,700);
        display: inline;
        @include ANDR {
          @include font(11)
        }
      }
      .cate {
        // @include flex(1);
        display: inline;
        span {
          color: #fff;
          @include font(12,12,0,700);
          // display: inline-block;
          display: inline;
          margin: 0 2px 2px 0;
          @include ANDR {
            @include font(10)
          }
        }
      }
    }
    &-main {
      width: 55%;
      height: 100%;
      @include SP {
        width: 100%;
      }
      .main-item {
        display: block;
        position: relative;
        height: 100%;
        overflow: hidden;
        .img {
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          @include aspect-ratio(583,334);
          // @include min-screen(768px) {
          //   @include aspect-ratio(380,267);
          // }
          // @include min-screen(992px) {
          //   @include aspect-ratio(583,334);
          // }
        }
        .ctn {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          @include flexbox();
          @include flex-direction(column);
          @include justify-content(flex-end);
          padding: 26px 38px;
          @include SP {
            padding: 18px 15px;
          }
          &:before {
            content: '';
            width: 100%;
            height: 67%;
            bottom: 0;
            left: 0;
            position: absolute;
            background: rgb(31,31,31);
            background: linear-gradient(0deg, rgba(31,31,31,1) 0%, rgba(31,31,31,0) 100%);
            z-index: -1;
            opacity: .75;
          }
          .tit {
            color: #fff;
            @include font(17,26,0,700);
            @include clamp(2);
          }
          .meta {
            .date {
              color: #fff;
            }
            .cate {
              span {
                color: #fff;
              }
            }
          }
        }
      }
    }
    &-default {
      width: 45%;
      padding-left: 32px;
      @include SP {
        width: 100%;
        padding: 0 23px 0 22px;
        margin-top: 26px;
      }
      .article-default-item {
        &:not(:last-child) {
          margin-bottom: 18px;
          @include SP {
            margin-bottom: 16px;
          }
        }
        a {
          @include flexbox();
          @include flex-wrap(wrap);
          .img {
            width: 38.9%;
            height: 100%;
            @include aspect-ratio(173,99);
            overflow: hidden;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            @include SP {
              width: 47%;
              @include aspect-ratio(155,90);
            }
          }
          .inf {
            width: 61.1%;
            padding-left: 20px;
            padding-top: 0;
            @include SP {
              width: 53%;
              padding-left: 17px;
            }
            .tit {
              @include font(16,24,0,700);
              @include clamp(2);
              @include min-screen(768px) {
                @include font(14,20);
              }
              @include min-screen(992px) {
                @include font(16,24);
              }
              @include SP {
                @include clamp(3);
                @include font(15,21);
              }
              @include ANDR {
                @include font(13);
              }
            }
            .meta {
              margin-top: 10px;
              @include SP {
                margin-top: 6px;
              }
              .date {
                color: $color_orange;
                @include SP {
                  @include font(11,11);
                }
                @include ANDR {
                  @include font(10,10);
                }
              }
              .cate {
                span {
                  color: $color_orange;
                  @include SP {
                    @include font(11,11);
                  }
                  @include ANDR {
                    @include font(10,10);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .interior-banner {
    padding-top: 105px;
    @include flexbox();
    @include flex-wrap(wrap);
    overflow: hidden;
    margin: 0 -10px;
    @include SP {
      padding-top: 47px;
    }
    li {
      width: 33.333%;
      padding: 0 10px;
      @include SP {
        width: 100%;
        margin: 13px 0;
      }
      a {
        display: block;
        line-height: 0;
        img {
          width: 100%;
          display: block;
        }
      }
    }
  }
}

.top-service {
  background: $color_light_gray;
  padding: 90px 0 85px;
  margin-bottom: 97px;
  @include SP {
    padding: 58px 0 65px;
    margin-bottom: 64px;
  }
  .service-box {
    @include flexbox();
    @include flex-wrap(wrap);
    margin: 33px 0 40px;
    @include SP {
      margin: 28px 0 14px;
    }
    &-text {
      width: 32%;
      @include SP {
        width: 100%;
      }
      .tit {
        @include font(22,35,50,700);
        margin: -6px 0 12px;
        @include min-screen(768px) {
          @include font(18,30);
        }
        @include min-screen(992px) {
          @include font(22,35);
        }
        @include SP {
          @include font(18,28);
          text-align: center;
        }
        @include ANDR {
          @include font(15);
        }
      }
      .txt {
        @include font(15,30,25);
        @include SP {
          @include font(15,25);
        }
        @include ANDR {
          @include font(13);
        }
      }
    }
    &-inf {
      width: 68%;
      padding-left: 43px;
      @include SP {
        width: 100%;
        padding-left: 0;
      }
      .service-img {
        @include flexbox();
        @include flex-wrap(wrap);
        overflow: hidden;
        margin: 0 -3px;
        padding-bottom: 17px;
        @include SP {
          padding-top: 23px;
          padding-bottom: 24px;
        }
        li {
          width: 50%;
          padding: 0 3px;
          @include SP {
            width: 100%;
            margin: 10px 0;
          }
          a {
            display: block;
          }
          img {
            width: 100%;
            display: block;
          }
        }
      }
      .service-list {
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(center);
        overflow: hidden;
        margin: 0 -3px;
        @include SP {
          margin: 0 -2px;
        }
        li {
          width: 20%;
          padding: 3px;
          @include min-screen(768px) {
            width: 33.333%;
          }
          @include min-screen(992px) {
            width: 20%;
          }
          @include SP {
            width: 33.333%;
            padding: 2px;
          }
          a {
            padding: 13px 5px;
            background: #fff;
            @include flexbox();
            @include flex-direction(column);
            @include align-items(center);
            @include justify-content(center);
            height: 100%;
            .icon {
              display: block;
              margin-bottom: 10px;
            }
            .txt {
              @include font(14,18,0,700);
              display: block;
              text-align: center;
              @include min-screen(992px) {
                @include font(12,18,0,700);
              }
              @include min-screen(1200px) {
                @include font(14,18,0,700);
              }
              @include ANDR {
                @include font(11,15);
              }
            }
          }
        }
      }
    }
  }
}

.top-brand {
  padding-bottom: 106px;
  @include SP {
    padding-bottom: 74px;
  }
  .cm-sub-description {
    margin-top: 16px;
    @include SP {
      margin-top: 10px;
    }
  }
  .box-brand {
    margin-top: 45px;
    @include SP {
      margin-top: 17px;
    }
  }
  .brand-list {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    li {
      text-align: center;
      margin: 14px 12px;
      @include SP {
        margin: 11px 0;
      }
    }
    &.small {
      li {
        margin: 14px 22px;
        @include SP {
          margin: 11px 0;
        }
      }   
    }
    @include SP {
      &:nth-child(1) {
        margin: 0 -5px;
        li {
          padding: 0 5px;
        }  
      }
      &:nth-child(2) {
        margin: 0 -15px;
        li {
          padding: 0 15px;
        }  
      }
      &:nth-child(3) {
        margin: 0 -13px;
        li {
          padding: 0 13px;
        }  
      }
      &:nth-child(4) {
        margin: 0 -20px;
        li {
          padding: 0 20px;
        }  
      }
    }
  }
  .cm-button {
    margin-top: 40px;
    @include SP {
      margin-top: 20px;
    }
  }
}

.top-product {
  background: $color_light_gray;
  padding: 90px 0 95px;
  @include SP {
    padding: 55px 0 63px;
  }
  .cm-sub-description {
    margin-top: 18px;
  }
  .product-list {
    @include flexbox();
    @include flex-wrap(wrap);
    overflow: hidden;
    margin: 0 -6px;
    padding: 38px 0 36px;
    @include SP {
      padding: 22px 0;
    }
    .product-item {
      width: 25%;
      padding: 5px 6px;
      @include min-screen(768px) {
        width: 50%;
      }
      @include min-screen(992px) {
        width: 25%;
      }
      @include SP {
        width: 100%;
        padding-top: 1px;
        padding-bottom: 1px;
      }
      a {
        @include flexbox();
        background: #fff;
        height: 100%;
        .img {
          width: 78px;
          @include flex(0 0 auto);
          @include aspect-ratio(78,66);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          overflow: hidden;
          @include SP {
            width: 23.5%;
          }
        }
        .tit {
          @include font(15,21);
          padding: 5px 5px 5px 15px;
          @include flexbox();
          @include align-items(center);
          @include ANDR {
            @include font(13);
          }
        }
      }
    }
  }
}

.top-digital {
  padding: 56px 0 53px;
  border-bottom: 1px solid #E3E3E3;
  @include SP {
    padding: 47px 0 60px;
    border-bottom: none;
  }
  .digital-box {
    @include flexbox();
    @include flex-wrap(wrap);
    @include align-items(center);
    &-img {
      width: 42.6%;
    }
    &-ctn {
      width: 57.4%;
      padding-left: 42px;
      margin-top: 10px;
      @include SP {
        width: 100%;
        padding: 0 15px;
      }
      .img {
        margin-top: 18px;
        img {
          width: 100%;
          display: block;
        }
      }
      .text {
        margin-top: 15px;
        @include font(15,24);
      }
      .cm-button {
        margin-left: 0;
        margin-top: 30px;
        @include min-screen(768px) {
          max-width: 298px;
        }
        @include SP {
          margin: 23px auto 0;
        }
      }
    }
  }
}









